import * as React from "react";
import { Helmet } from "react-helmet";
import { globalHistory } from "@reach/router";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import Template from "../components/Template";

import MusicYAML from "../content/music.yaml";
import HotTracks from "../content/hottracks.yaml";
import MyTracks from "../content/mytracks.yaml";

import * as styles from "./music.module.scss";

const soundcloud = `
<iframe width="300" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1241061052&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/flexplainer" title="François" target="_blank" style="color: #cccccc; text-decoration: none;">François</a> · <a href="https://soundcloud.com/flexplainer/sets/hopeful-fool" title="Hopeful Fool" target="_blank" style="color: #cccccc; text-decoration: none;">Hopeful Fool</a></div>
`;

const Album = ({ props }) => (
  <div className={styles.album}>
    <img src={props.coverUrl} width={200}></img>
    <br />
    <b>
      {props.title} ({props.year})
    </b>
    <br />
    by {props.artist}
    <br />
    Favorite track: {props.bestSong}
  </div>
);

const MusicPage = ({ location }) => {
  const [song, setSong] = React.useState(HotTracks[0]);
  const [mySong, setMySong] = React.useState(MyTracks[0]);
  const [mySongIndex, setMySongIndex] = React.useState(0);
  const pageLang = !globalHistory.location.pathname.includes("/fr")
    ? "en"
    : "fr";

  const show = (lang) => {
    if (lang === "en" && pageLang === "en") return { display: "block" };
    else if (lang === "fr" && pageLang === "fr") return { display: "block" };
    else return { display: "none" };
  };

  return (
    <Template location={location}>
      <Helmet>
        <title>{`Charih / ${pageLang === "en" ? "Music" : "Musique"}`}</title>
        <script
          src="https://kit.fontawesome.com/ab30a1a18b.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className={styles.container}>
        <h1 style={show("en")}>Music</h1>
        <h1 style={show("fr")}>Musique</h1>
        {/*<div style={show("fr")}>
          La musique fait partie intégrante de ma vie. J'irais jusqu'à dire
          qu'elle fut mon premier amour. Je suis fan de musiques en tous genres,
          mais j'affectionne particulièrement la musique pop, acoustique, rock
          et le rock indépendant. Voici quelques chansons qui me font vibrer
          présentement 🔥🔥🔥 :
        </div>
        <div style={show("en")}>
          Music is an essential part of my life. I'm particularly fond of pop,
          folk, rock, electronica, although I will listen to anything. Here are
          some new and less new — but always tasty — tracks that I've been
          enjoying recently 🔥🔥🔥 :
        </div>
        <div className={styles.hotTracksContainer}>
          <ReactPlayer controls height={200} width={300} url={song.url} />
          {HotTracks.filter((track) => track.show).map((track) => (
            <div className={styles.track} onClick={() => setSong(track)}>
              <div className={styles.trackInfo}>
                <b>{track.title}</b> by {track.artist}
                <br />
                in {track.record} ({track.year})
              </div>
              <div
                style={{
                  display: `${song.title != track.title ? "none" : "block"}`,
                }}
              >
                🔊
              </div>
            </div>
          ))}
              </div>*/}

        <h2 style={show("en")}>Songwriting</h2>
        <h2 style={show("fr")}>Composition</h2>
        <div style={show("fr")}>
          <p>
            Dans mes temps libres, j'aime écrire de la musique. Je compose des
            chansons depuis l'adolescence.
          </p>
          <p>
            Mes études ont accaparé beaucoup de mon temps, ce qui m'en a laissé
            trop peu pour jouer et écrire des chansons. L'art et la musique sont
            importants, mais combattre les maladies l'est aussi. Comme
            j'approche la fin de mon doctorat, je suis en train de me remettre à
            l'écriture.
          </p>
          <p>
            Vous pouvez écouter mon EP
            <i>Tales of a Hopeless Romantic</i> qui consiste surtout de chansons
            écrites en début vingtaine ici en attendant.
          </p>
        </div>
        <div style={show("en")}>
          <p>
            In my spare times, I enjoy playing the guitar and singing. I started
            songwriting in my late teens.
          </p>
          <p>
            Life (grad school) got in the way and left me with very little time
            to play and write music. Art and music are important, but fighting
            diseases is important too! I'm starting to get back into it as I'm
            wrapping up that PhD and am writing new songs.
          </p>
          <p>
            You can listen to <i>Tales of a Hopeless Romantic</i> which is a
            collection of songs I wrote in my early twenties in the meantime.
          </p>
        </div>
        <div style={{ width: 350, margin: "10px auto" }}>
          <iframe
            style={{
              display: "block",
              margin: "20px auto",
              border: 0,
              width: "350px",
              height: "550px",
            }}
            src="https://bandcamp.com/EmbeddedPlayer/album=485257816/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=true/transparent=true/"
            seamless
          ></iframe>
        </div>
        <h2 style={show("en")}>What I'm listening to</h2>
        <h2 style={show("fr")}>Ce que j'écoute</h2>
        <div style={show("fr")}>
          Voici quelques chansons qui me font vibrer présentement 🔥🔥🔥 :
        </div>
        <div>
          {" "}
          Here are some new and less new — but always tasty — tracks that I've
          been enjoying recently 🔥🔥🔥 :
        </div>
        <div className={styles.hotTracksContainer}>
          <ReactPlayer controls height={200} width={300} url={song.url} />
          {HotTracks.filter((track) => track.show).map((track) => (
            <div className={styles.track} onClick={() => setSong(track)}>
              <div className={styles.trackInfo}>
                <b>{track.title}</b> by {track.artist}
                <br />
                in {track.record} ({track.year})
              </div>
              <div
                style={{
                  display: `${song.title != track.title ? "none" : "block"}`,
                }}
              >
                🔊
              </div>
            </div>
          ))}
        </div>
      </div>
    </Template>
  );
};

export default MusicPage;
